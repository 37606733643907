.footer {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../images/footer.png');
    position: relative;
    margin-top: auto;
    &::before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: var(--footer-color);
        opacity: 0.95;;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
    }
    .container {
        position: relative;
        z-index: 1;
    }

}
.footer-logo-area {
    .footer-logo{
        max-width: 150px;
    }
    p {
        font-size: 15px;
        color: #dfdfdf;
        margin: {
            top: 20px;
            bottom: 20px;
        };
    }
    .footer-social-area {
        ul {
            margin: 0px;
            padding: 0px;
            list-style: none;
            li {
                display: inline-block;
                &:not(:last-child){
                    margin-right: 5px;
                }
                span {
                    color: var(--whiteColor);
                    margin-right: 10px;
                    font: {
                        size: 15px;
                        weight: 500;
                        family: var(--fontFamily2);
                    };
                }
                a {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--mainColor);
                    background-color: var(--whiteColor);
                    &:hover {
                        color: var(--whiteColor);
                        background-color: var(--mainColor);
                    }
                }
            }
        }
    }
}
.footer-links {
    h3 {
        color: var(--whiteColor);
        margin-bottom: 20px;
        padding-bottom: 15px;
        position: relative;
        font: {
            size:var(--card-title-fontSize);
            weight: 600; 
        };
        &::before {
            content: '';
            width: 30%;
            height: 1px;
            background-color: var(--mainColor);
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
}

.footer-contact {
    padding-left: 30px;
}
.footer-contact-card {
    position: relative;
    padding-left: 25px;
    margin-top: 10px;
    i {
        position: absolute;
        left: 0;
        color: var(--footerIconColor);
    }
    h5 {
        margin-bottom: 5px;
        color: var(--whiteColor);
        font: {
            size: var(--fontSize);
            weight: 600;
        };
    }
    p {
        font-size: 14px;
        color: var(--whiteColor);
        a {
            color: var(--whiteColor);
        }
    }
}
.footer-quick-links {
    padding-left: 20px;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            margin-top: 12px;
            color: var(--mainColor);
            font: {
                size: 15px;
                family: var(--fontFamily2);
            };
            a {
                margin-left: 5px;
                color: var(--whiteColor);
                &:hover {
                    color: var(--mainColor);
                }
            }
        }
    }
}
.footer-newsletter {
    margin-bottom: -10px;
    margin-top: -2px;
    p {
        color: var(--whiteColor);
        font-size: 15px;
    }
    .newsletter-form {
        &::placeholder {
            color: var(--whiteColor) !important;
        }
        .form-control {
            color: var(--whiteColor) !important;
            background-color: transparent;
            border: 0px;
            padding: 10px 15px;
            border: 1px solid #eeeeee50;
            border-radius: 10px;
            font: {
                size: 15px;
                family: var(--fontFamily2);
            };
            &::placeholder {
                color: var(--whiteColor);
            }
        }
        .news-btn {
            width: 100%;
            padding: 10px 15px;
            margin-top: 10px;
            &:hover {
                background-color: var(--blackColor);
            }
        }
    }
}
.validation-danger {
    color: red;
}
.validation-success {
    color: green;
}

/*================================================ 
CopyRight Area CSS
=================================================*/
.copyright {
    text-align: center;
    color: var(--whiteColor);
    position: relative;
    padding: {
        top: 0px;
        bottom: 20px;
    };
    p {
        padding-top: 20px;
        color: #dfdfdf;
        border-top: 1px solid #eeeeee20;
        font: {
            size: 15px;
        };
        a {
            font-weight: 600;
            text-decoration: none;
            color: var(--mainColor);
        }
        strong{
            color: var(--whiteColor);
        }
    }
}


/*================================================
Go Top CSS
=================================================*/
.go-top {
    position: fixed;
    cursor: pointer;
    bottom: 0px;
    right: 20px;
    border-radius: 5px;
    color: var(--mainColor);
    z-index: 99;
    height: 40px;
    width: 50px;
    display: inline-block;
    padding: 0 10px 45px;
    text-align: center;
    opacity: 1;
    visibility: hidden;
    font-size: 25px;
    -webkit-transition: var(--transition);
    transition: 0.5s ease-in-out;
    -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 10px 2px rgba(7, 7, 7, 0.1);
    i{
        position: absolute;
        right: 0;
        left: 0;
        z-index: 9;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    &:hover {
        transition: all 0.5s linear;
    }
}
.go-top.active {
        opacity: 1;
        visibility: visible;
        bottom: 65px;
        animation: top-bottom 5s infinite linear;
}